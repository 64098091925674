module controllers {
    export module purchase {
        interface IDeliveryInstructionSunUpdateScope extends ng.IScope {
            
        }

        interface IDeliveryInstructionSundryUpdateParams extends ng.ui.IStateParamsService {
            pdsId: number,
            diId: number
        }
        export class deliveryInstructionSunUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', "bsLoadingOverlayService", 'currencyService',
                'deliveryInstructionSundryItemService', 'classificationValueService', 'unitOfMeasureService',
                'sundryItemService', 'costModelElementService', 'vatRateService', '$stateParams', '$state', "$anchorScroll"];

            diSundryItem: interfaces.purchase.IDeliveryInstructionSundryItem;
            public Messages: Array<interfaces.applicationcore.IMessageItem>;

            breadCrumbDesc: string;

            constructor(public $scope: IDeliveryInstructionSunUpdateScope, private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService, public $q: ng.IQService, public bsLoadingOverlayService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private deliveryInstructionSundryItemService: interfaces.purchase.IDeliveryInstructionSundryItemService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                private sundryItemService: interfaces.master.ISundryItemService,
                private costModelElementService: interfaces.costing.ICostModelElementService,
                private vatRateService: interfaces.master.IVatRateService,
                private $stateParams: IDeliveryInstructionSundryUpdateParams,
                private $state: ng.ui.IStateService,
                private $anchorScroll: ng.IAnchorScrollService) {

                bsLoadingOverlayService.wrap({
                    referenceId: 'di.sun.update'
                },
                    () => {
                        return this.loadDiSundryItem();
                        });
            }

            loadDiSundryItem() {
                var defer = this.$q.defer();

                this.deliveryInstructionSundryItemService.getDetail().get({ Id: this.$stateParams.pdsId, pdiId: this.$stateParams.diId }, (result) => {
                    this.diSundryItem = result;

                    if (this.diSundryItem.SundryItem)
                        this.breadCrumbDesc = this.diSundryItem.SundryItem.Code;

                    defer.resolve();
                }, (errorResponse) => {
                    defer.reject();
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
                return defer.promise;
            }

            Save() {
                this.generalService.displayConfirmationBox("Confirm", "Save Delivery Instruction Sundry Item?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'di.sun.update'
                        },
                            () => {
                                return this.deliveryInstructionSundryItemService.save().save(this.diSundryItem, (msgHandler) => {
                                    this.generalService.displayMessageHandler(msgHandler);

                                    if (this.diSundryItem.Id)
                                        this.loadDiSundryItem();
                                    else {
                                        this.$state.params["pdsId"] = Number(msgHandler.ID);
                                        this.$state.go('auth.PurchaseOrders.Update.Di.SundryItem', this.$state.params, { reload: 'auth.PurchaseOrders.Update.Di.SundryItem' });
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });
                    }
                });

                
                
            }

            SaveClose() {
                this.generalService.displayConfirmationBox("Confirm", "Save Delivery Instruction Sundry Item?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'di.sun.update'
                        },
                            () => {
                                return this.deliveryInstructionSundryItemService.save().save(this.diSundryItem, (msgHandler: interfaces.applicationcore.IMessageHandler) => {
                                    this.generalService.displayMessageHandler(msgHandler);
                                    if (!msgHandler.HasErrorMessage) {
                                        this.$state.go("^");
                                        this.$anchorScroll("topAnchor");
                                    } else {
                                        this.$anchorScroll("topAnchor");
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });
                    }
                });

            }

            Close() {
                this.$state.go("^");
            }

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadApportionments() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CustomsApportionment
                }, () => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadUoMApportionments() {
                if (this.diSundryItem && this.diSundryItem.OwnerEntityId) {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.diSundryItem.OwnerEntityId }, () => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadSundryItem(searchText: string) {
                if (this.diSundryItem) {
                    //var costModelId: number = (this.diSundryItem.CostModelId === null) ? 0 : this.diSundryItem.CostModelId;

                    return this.sundryItemService.getDropdownListForCostModel(this.diSundryItem.OwnerEntityId, this.diSundryItem.CostModelId, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadCostModelElement(searchText: string) {
                if (this.diSundryItem) {
                    return this.costModelElementService.getDropdownList(this.diSundryItem.OwnerEntityId).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadVatRate(searchText: string) {
                if (this.diSundryItem) {
                    return this.vatRateService.getDropdownList().query({ ownerEntityId: this.diSundryItem.OwnerEntityId, countryId: this.diSundryItem.CountryOfDestinationId }, () => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            sundryItemChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'di.sun.update'
                    },
                        () => {
                            return this.sundryItemService.getDefault().get({ sunId: model.Id, countryId: this.diSundryItem.CountryOfDestinationId }, (result: interfaces.master.ISundryItemDefault) => {
                                this.setDefaults(result);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            setDefaults(defaults: interfaces.master.ISundryItemDefault) {
                this.diSundryItem.SundryItemDataDescriptionId = defaults.ShortDataDescriptionId;
                this.diSundryItem.IsDutiable = false;
                this.diSundryItem.IsIncludedInPrice = false;
                this.diSundryItem.ApportionmentClassificationValue = defaults.ApportionmentClassificationValue;
                this.diSundryItem.ApportionmentUnitOfMeasure = defaults.ApportionmentUnitOfMeasure;
                this.diSundryItem.VATRate = defaults.VatRateRate;
                this.diSundryItem.VATRateRate = defaults.VatRate;
                this.diSundryItem.CostModelElement = defaults.CostModelElement;

                this.calculateVatValue();
            }

            vatRateChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'di.sun.update'
                    },
                        () => {
                            return this.vatRateService.getVatRate().get({ mvrId: model.Id }, (vatRate: interfaces.master.IVatRate) => {
                                this.diSundryItem.VATRate = vatRate.CurrentRate;
                                this.calculateVatValue();
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                } else {
                    this.diSundryItem.VATRate = 0;
                    this.calculateVatValue();
                }
            }

            exchangeRateChanged() {
                this.calculateBaseSellingValue(this.diSundryItem.SellingValue, this.diSundryItem.ExchangeRate);
            }

            calculateSellingValue() {
                this.diSundryItem.SellingValue = Number((this.diSundryItem.Quantity * this.diSundryItem.SellingPrice).toFixed(2));
                this.calculateVatValue();
            }

            calculateVatValue() {
                if (this.diSundryItem.VATRate > 0)
                    this.diSundryItem.VATValue = Number((this.diSundryItem.SellingValue * this.diSundryItem.VATRate / 100).toFixed(2));
                else
                    this.diSundryItem.VATValue = 0;
                this.calculateBaseSellingValue(this.diSundryItem.SellingValue, this.diSundryItem.ExchangeRate);
            }

            calculateBaseSellingValue(sellingValue: number, exchangeRate: number) {
                this.diSundryItem.BaseCurrencyValue = Number((sellingValue * exchangeRate).toFixed(2));
            }
        }

    angular.module("app").controller("deliveryInstructionSunUpdateCtrl", controllers.purchase.deliveryInstructionSunUpdateCtrl);
    }
}